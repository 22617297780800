<template>
  <div class="wrapper">
    <div class="store_wrapper">
      <div class="search_header">
        <Search
          v-model.trim="value"
          shape="round"
          show-action
          placeholder="搜索药品名称"
        >
          <template #left-icon>
            <div class="left_icon">
              <img :src="require('@img/icon_search_grey.png')" alt="" />
            </div>
          </template>
          <template #action>
            <div
              :class="{ btn_active: value.length }"
              class="btn"
              @click="toSearch"
            >
              搜索
            </div>
          </template>
        </Search>
      </div>
      <div class="header">
        <img
          v-if="leftTagsData.pictureUrl"
          :src="leftTagsData.pictureUrl"
          alt=""
          class="avatar"
        />
        <img v-else :src="require('@img/store.png')" class="avatar" alt="" />
        <div class="info_wrapper">
          <span class="info store_name cete">{{ leftTagsData.fullName }}</span>
          <span class="info store_address"
            ><span>{{
              leftTagsData.provinceStr +
              '' +
              leftTagsData.cityStr +
              leftTagsData.address
            }}</span></span
          >
          <span class="info store_tel">
            <a :href="'tel:' + '400 052 3990'">400 052 3990</a></span
          >
        </div>
      </div>
      <div class="content">
        <!-- <div class="tag_wrapper">
          <ul class="">
            <li
              v-for="(tag, index) in tagsList"
              :key="tag.tagId"
              :class="[{ active: isActive === index }]"
              class="tag"
              @click="handleTagClick(tag, index)">
              <span>{{ tag.tagName }} </span>
            </li>
          </ul>
        </div> -->
        <div class="list">
          <List
            v-model="loading"
            :finished="finished"
            class="drug_list"
            finished-text="到底啦～"
            @load="onLoad"
          >
            <DrugItem
              v-for="drugData in goodsList"
              :key="drugData.goodsId"
              :show-spes="false"
              :data="drugData"
              :member-price="0"
              @click="goDrugDetail(drugData)"
            >
              <!-- <template v-slot:numOrCount>
                <div class="add">
                  <Counter :attr="mapDataToAttr(drugData)" />
                </div>
              </template> -->
            </DrugItem>
          </List>
        </div>
      </div>
      <!-- <Shopping-cart
        v-if="this.$route.params.merchantCode"
        :merchant-code="this.$route.params.merchantCode"
      /> -->
    </div>
    <div class="bottom_url" v-if="host === 'shyboai.com'">
      沈阳三合缘博爱药房有限公司&nbsp;<a href="https://beian.miit.gov.cn/"
        >辽ICP备2021001219号-1</a
      >
    </div>
    <div class="bottom_url" v-if="host === 'shyzong.com'">
      沈阳三合缘药房有限公司&nbsp;<a href="https://beian.miit.gov.cn/"
        >辽ICP备2021001222号-1</a
      >
    </div>
  </div>
</template>

<script>
import { List, Search } from 'vant';
import DrugItem from '@/components/order/drug-item';
import ShoppingCart from '@/components/shopping-cart';
import Counter from '@/components/business/add-subtract';
import { mapGetters, mapState, mapMutations } from 'vuex';
import api from '@/api';
import WxTool from '@/utils/wx-tool';
import Configs from '@/config';

export default {
  components: {
    // vanPullRefresh: PullRefresh,
    List,
    DrugItem,
    ShoppingCart,
    Counter,
    Search,
  },
  data () {
    return {
      host: '',
      logo: require('./logn.jpg'),
      isActive: 0,
      leftTagsData: {},
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      pageNumber: 1,
      pageSize: 10,
      // tagsList: [
      //   {
      //     tagName: '全部',
      //     tagId: '',
      //   },
      // ],
      tagId: '',
      goodsList: [
        // {
        //     name: 'Bio-Island孕妇专用DHA澳洲海藻油',
        //     price: '199.00',
        //     img: 'https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1605100847903&di=e0daef5bd0700a18da805d0987c0e711&imgtype=0&src=http%3A%2F%2Fimgcdn.baiji.com.cn%2Fimages%2F201907%2Fgoods_img%2F17068_G_1564552939117.jpg',
        // },
      ],
      merchantCode: '',
      value: '',
      leaveing: true,
    };
  },
  computed: {
    ...mapState({
      shoppingList: (store) => store.shoppingCart.list,
    }),
    ...mapGetters('shoppingCart', [
      'mapDataToAttr',
    ]),
    channelCode () {
      return localStorage.getItem('channelCode');
    },
  },
  created () {
    this.merchantCode = this.$route.params.merchantCode;
    // this.getTagsData();
    this.getStoreData();
  },
  mounted () {
    this.host = window.location.host
    console.log('this.host', this.host)
  },

  watch: {
    "$route.path": (val1, val2) => {
      console.log('val1', val1)
    }
  },
  methods: {
    ...mapMutations('merchantInfo', [
      'addMerchantInfo',
    ]),
    handleTagClick (tag, index) {
      this.isActive = index;
      this.pageNumber = 1;
      this.goodsList = [];
      this.loading = true;// 下拉加载中
      this.finished = false;// 下拉结束
      this.tagId = tag.tagId;
      if (this.leaveing) {
        this.getStoreData();
      }
    },

    onLoad () {
      if (this.totalPage && this.pageNumber < this.totalPage) {
        this.pageNumber += 1;
        this.getStoreData();
      }
    },

    getStoreData () {
      this.leaveing = false;
      const param = {
        drugstoreCode: this.merchantCode,
        keyword: '片'
        // tagId: this.tagId,
        // pageNumber: this.pageNumber,
        // pageSize: this.pageSize,
      };

      // 如果是从  会员卡详情--查看适用门店  接入，需要以下参数
      const { productCodes } = this.$route.query;
      if (productCodes) {
        param.productCodes = productCodes.split(',');
      }
      if (this.channelCode === 'PAK') { // 派安康来源
        param.saleChannel = Configs.channelCode[this.channelCode.toUpperCase()];
      }
      api.getSHYStoreInfo(param)
        .then((res) => {
          const { goodsInfo, pharmacyInfo } = res.data;
          // this.goodsList = this.goodsList.concat(list);
          this.goodsList = goodsInfo || [];
          this.leftTagsData = pharmacyInfo;
          console.log('pharmacyInfo.fullName', pharmacyInfo.fullName)
          document.title = pharmacyInfo.fullName
          this.totalPage = 1;
          this.loading = false;
          this.leaveing = true;
          if (this.pageNumber >= this.totalPage) {
            this.finished = true;
          }
        });
    },
    // getTagsData() {
    //   const params = {
    //     merchantCode: this.merchantCode,
    //   };
    //   if (this.channelCode === 'PAK') { // 派安康来源
    //     params.saleChannel = Configs.channelCode[this.channelCode.toUpperCase()];
    //   }
    //   api.getStoreTags(params)
    //     .then((res) => {
    //       this.leftTagsData = res.data;
    //       // this.tagsList = this.tagsList.concat(res.data.tagList);
    //       document.title = res.data.fullName;

    //       // 缓存药店名称和code
    //       const param = {
    //         drugstoreCode: this.leftTagsData.groupCode,
    //         name: this.leftTagsData.fullName,
    //       };
    //       this.addMerchantInfo(param);

    //       // 分享参数更新
    //       const instance = WxTool.getInstance();
    //       instance.onShare({
    //         desc: res.data.name,
    //         imgUrl: res.data.pictureUrl,
    //       });
    //     });
    // },
    goDrugDetail (item) {
      const {
        salePrice,
        suggestSalePrice,
        name,
        usualName,
        manufacturerName,
        packaging,
        specsValue,
        permissionNo,
        prescription,
      } = item;

      const {
        fullName, groupCode, pictureUrl, cityStr,
      } = this.leftTagsData;

      this.$router.push({
        name: 'drug-shy',
        params: {
          merchantCode: this.$route.params.merchantCode,
          // merchantName: leftTagsData.name,
          salePrice: salePrice / 100,
          usualName,
          productName: name,
          cityStr,
          specs: specsValue,
          packaging,
          permissionNo,
          providerName: manufacturerName,
          fullName,
          groupCode,
          pictureUrl,
          prescription,
        },
      });
    },
    toSearch () {
      if (!this.value) {
        return;
      }
      this.$router.push({
        name: 'search-store-shy',
        query: {
          search: this.value,
          merchantCode: this.$route.params.merchantCode,
          merchantName: this.leftTagsData.name,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.store_wrapper {
  // padding-bottom: 50px;
  height: 100%;
  background: #fff;
  .search_header {
    box-shadow: 0 0 8px 0 rgba(60, 107, 240, 0.1);
    height: 44px;
    .left_icon {
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .van-cell {
      padding: 0;
      line-height: 28px;
      font-size: 14px;
    }

    .van-search {
      padding: 5px 18px;
    }
    /deep/ .van-cell {
      padding-right: 4px;
    }
    /deep/ .van-field__control {
      font-weight: 400;
    }
    /deep/ .van-field__left-icon {
      display: flex;
    }
    .btn {
      color: #cfcfd2;
    }
    .btn_active {
      color: #3679f0;
    }
  }
  .header {
    box-sizing: border-box;
    width: 100%;
    // height: 112px;
    padding: 15px 15px 13px 15px;
    display: flex;
    // box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1);
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    .avatar {
      // display: inline-block;
      margin-right: 9px;
      width: 65px;
      height: 65px;
      // box-shadow: 0 0 10px 0 rgba(54, 121, 240, 0.1);
      border-radius: 6px;
    }
    .info_wrapper {
      // display: inline-block;
      // width: 286px;
      .info {
        display: inline-block;
        margin-bottom: 4px;
      }
      .store_name {
        height: 22px;
        font-size: 16px;
        font-weight: 600;
        color: #333;
        line-height: 22px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        width: 230px;
      }
      .store_address {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        // display: inline-block;
        width: 250px;
        font-size: 12px;
        font-weight: 400;
        color: #333;
        line-height: 17px;
        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          margin-right: 1px;
          top: 4px;
          background: url('./icon_location_m@2x.png');
          background-size: 16px 16px;
        }
      }
      .store_tel {
        height: 20px;
        font-size: 14px;
        font-weight: 500;
        color: #0672ef;
        line-height: 20px;
        &::before {
          content: '';
          display: inline-block;
          width: 16px;
          height: 16px;
          position: relative;
          top: 4px;
          margin-right: 2px;
          background: url('./icon_tel_m@2x.png');
          background-size: 16px 16px;
        }
      }
    }
  }

  .content {
    height: calc(100% - 112px);
    display: flex;
    .tag_wrapper {
      width: 102px;
      background: #f5f7fa;
      overflow-y: scroll;
      overflow-x: hidden;
      height: calc(100vh - 186px);
      margin-bottom: 16px;
      .tag {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 102px;
        height: 50px;
        font-size: 14px;
        font-weight: 400;
        color: #333;
        line-height: 20px;
      }
      .active {
        background: #fff;
        border-left: 2px solid #f76d32;
      }
    }
    .goods {
      flex: 1;
      .medicine_wrapper {
        display: flex;
        box-sizing: border-box;
        width: calc(100% - 24px);
        // padding: 12px 12px 15px 12px;
        padding-bottom: 15px;
        margin: 12px 12px 0 12px;
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
        .medicine_img {
          width: 56px;
          height: 56px;
          padding: 7px 5px 5px 6px;
          margin-right: 12px;
          background: #edf1f8;
          border-radius: 4px;
        }
        .medicine_info {
          span {
            display: inline-block;
          }
          .medicine_info_name {
            height: 40px;
            font-size: 14px;
            font-weight: 500;
            color: #333;
            line-height: 20px;
          }
          .medicine_info_price {
            height: 19px;
            font-size: 14px;
            font-weight: 500;
            color: #e94947;
            line-height: 19px;
          }
        }
      }
    }
  }

  .list {
    padding: 4px 12px 0;
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    .drug_list {
      height: calc(100vh - 220px);
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }
  /deep/ .van-list__finished-text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 18px 0;
    width: auto;
    font-size: 11px;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #babfc9;
    line-height: 16px;

    &::before,
    &::after {
      content: '';
      display: inline-block;
      margin: 0 8px;
      width: 40px;
      height: 1px;
      background: #dcdfe4;
      color: red;
    }
  }
}

.bottom_url {
  height: 50px;
  line-height: 50px;
  background: #fff;
  text-align: center;
  a {
    color: rgb(29, 29, 29);
  }
}
</style>
